import { useContext, useEffect, useState } from "react"
import { Button, Container, Form, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { UserContext } from "../contexts/UserContext"

function AdminPanel() {
    const [users, setUsers] = useState()
    const [logs, setLogs] = useState()
    const [userSearch, setUserSearch] = useState()

    const { getAllUsers, getAdminStatus, getAllLogs, searchUsers } = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        async function gettingLogs() {
            let allLogs = await getAllLogs()
            setLogs(allLogs)
        }
        gettingLogs()

        async function verifying() {
            let admn = await getAdminStatus()
            if (!admn) {
                navigate('/')
            }
        }
        verifying()

        async function gettingUsers() {
            let usrs = await getAllUsers()
            setUsers(usrs)
        }
        gettingUsers()
    }, [getAdminStatus, getAllLogs, getAllUsers, navigate])

    function formatDate(inputDate) {
        // Create a new Date object from the input string
        const date = new Date(inputDate);

        // Extract the date components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');

        // Extract the time components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Construct the formatted date string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }

    async function handleSearch(value) {
        if (value.length > 0) {
            let users = await searchUsers(value)
            setUsers(users)
        }
        setUserSearch(value)
    }

    function mapThroughUsers() {
        if (users) {
            if (users.length > 0) {
                return users.map((user) => {
                    return (
                        <>
                            <div
                                className="col-1">
                                {user.userId}
                            </div>
                            <div className="col-4 col-md-3 col-lg-2">
                                {user.name} - {user.userType}
                            </div>
                            <div className="col-4 col-lg-3">
                                {user.active ? (
                                    <>
                                        {user.accountType} - Paid
                                    </>
                                ) : (
                                    <>
                                        {user.accountType} - Not Paid
                                    </>
                                )}
                            </div>
                            <div className="col-12 col-md-3 col-lg-5">
                                <Link to={`/admin-edit-account/${user.userId}`}>
                                    {user.email}
                                </Link>
                            </div>
                            <hr />
                        </>
                    )
                })
            } else {
                return <></>
            }
        } else {
            return <></>
        }
    }

    function mapThroughLogs() {
        if (logs) {
            if (logs.length > 0) {
                return logs.map((log) => {
                    return (
                        <>
                            <div className="col-1">
                                {log.logId}
                                {log.userId ? (
                                    <> - {log.userId}</>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="col-4 col-md-3 col-lg-2">
                                {log.type}
                            </div>
                            <div className="col-7 col-md-3 col-xl-2">
                                {formatDate(log.createdAt)}
                            </div>
                            <div className="col-12 col-lg-6 col-xl-7">
                                {log.title}
                            </div>
                            <hr />
                        </>
                    )
                })
            } else {
                return <></>
            }
        } else {
            return <></>
        }
    }

    return (
        <>
            <div
                className="searchNav">
                <Container>
                    <Row className="align-items-center">
                        <div className="col-4 col-md-3 col-lg-2 wordmark-area">
                            <img 
                            alt="Reactive Pass wordmark"
                            src="/reactive_pass_wordmark_alt.svg" 
                            className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-6 col-lg-7"></div>
                        <Link to="/" className="col-4 col-md-3">
                            <Button className="topMenuBtn w-100">
                                Home
                            </Button>
                        </Link>
                    </Row>
                </Container>
            </div>
            <br />
            <Container>
                <div className="text-color">
                    <Row>
                        <div className="col-12 col-md-6">
                            <Link className="textNone" to={`/admin-create-account`}>
                                <div className="col-12 box">
                                    <center>
                                        <h2>Create An Account</h2>
                                    </center>
                                </div>
                            </Link>
                        </div>
                    </Row>
                    <br />
                    <h2>
                        <b>Users:</b>
                    </h2>
                    <Row>
                        <Form.Control 
                            className="col-12 input-background"
                            value={userSearch}
                            onChange={(e) => handleSearch(e.target.value)} />
                    </Row>
                    <Row>
                        {mapThroughUsers()}
                    </Row>
                    <br />
                    <Row>
                        <h2>
                            <b>Logs:</b>
                        </h2>
                        {mapThroughLogs()}
                        <br /> <br /> <br />
                    </Row>
                </div>
            </Container>
        </>
    )
}
export default AdminPanel