import { useContext, useEffect, useState } from "react"
import { Button, Container, Form, Row } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { UserContext } from "../contexts/UserContext"

function AdminCreateAccount() {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [accountType, setAccountType] = useState('given')
    const [userType, setUserType] = useState('standard')
    const [message, setMessage] = useState()
    const [active, setActive] = useState("true")

    const { AdminCreateAccount, getAdminStatus } = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        async function verifying() {
            let admn = await getAdminStatus()
            if (!admn) {
                navigate('/')
            }
        }
        verifying()
    },[getAdminStatus, navigate])

    async function handleSubmit() {
        let user = {
            name: name,
            email: email,
            password: password,
            accountType: accountType,
            userType: userType,
            active: active
        }
        console.log(user)
        let newAcc = await AdminCreateAccount(user)
        if (newAcc.email && newAcc.userId) {
            console.log(newAcc)
        } else if (newAcc === "email in use") {
            setMessage("There is already and account associated with this email")
        } else {
            setMessage("There was an issue creating your account, please make sure all inputs are filled")
        }
    }

    return (
        <>
            <div
                className="searchNav">
                <Container>
                    <Row className="align-items-center">
                        <div className="col-4 col-md-3 col-lg-2 wordmark-area">
                            <img 
                            alt="Reactive Pass wordmark"
                            src="/reactive_pass_wordmark_alt.svg" 
                            className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-6 col-lg-7"></div>
                        <Link to="/" className="col-4 col-md-3">
                            <Button className="topMenuBtn w-100">
                                Home
                            </Button>
                        </Link>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <br />
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>Name</b></h3></Form.Label>
                        <Form.Control
                            value={name}
                            onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>Email</b></h3></Form.Label>
                        <Form.Control
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>Password</b></h3></Form.Label>
                        <Form.Control
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>AccountType</b></h3></Form.Label>
                        <Form.Select onChange={(e) => setAccountType(e.target.value)}>
                            <option>given</option>
                            <option>permanent</option>
                            <option>subscription</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>UserType</b></h3></Form.Label>
                        <Form.Select onChange={(e) => setUserType(e.target.value)}>
                            <option>standard</option>
                            <option>admin</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>Active</b></h3></Form.Label>
                        <Form.Select onChange={(e) => setActive(e.target.value)}>
                            <option>true</option>
                            <option>false</option>
                        </Form.Select>
                    </Form.Group>
                    <br /><br /><br /><br /><br />
                    <Row>


                        <div className="col-12">
                            <Row>
                                <div className="col-md-2" />
                                <Button className="col-12 col-md-8"
                                    onClick={handleSubmit}>Submit</Button>
                                <div className="col-md-2" />
                            </Row>
                        </div>

                    </Row>

                    <Row>
                        <div className='col-md-2' />
                        <div className='col-12'>
                            <br />
                            {message ? (
                                <>
                                    <div className='message'>
                                        <center>
                                            {message}
                                        </center>
                                    </div>
                                    <br />
                                </>
                            ) : (
                                <>
                                </>
                            )}
                        </div>
                    </Row>
                </Row>
            </Container>
        </>
    )
}
export default AdminCreateAccount