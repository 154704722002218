import { useContext, useState } from 'react'
import { Button, Card, Container, Form, Modal, Row } from 'react-bootstrap'
import { UserContext } from '../contexts/UserContext'
import { Link, useNavigate } from 'react-router-dom'
import './LoginPage.css'
import ClipLoader from 'react-spinners/ClipLoader'

function Loginpage() {
    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [token, setToken] = useState("");
    const [message, setMessage] = useState()
    const [paymentLink, setPaymetLink] = useState()
    // const [total, setTotal] = useState("$8 per 3 months")
    const [awaitingLogin, setAwaitingLogin] = useState(false)

    const { login } = useContext(UserContext)
    const navigate = useNavigate()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [secondShow, setSecondShow] = useState(false)
    const handleSecondClose = () => setSecondShow(false);
    const handleSecondShow = () => setSecondShow(true);

    async function handleSubmit() {
        try {
            setAwaitingLogin(true)
            if (email !== undefined && password !== undefined) {
                let credentials = {
                    email,
                    password,
                    token
                }
                let res = await login(credentials)
                if (res.data.success === true) {
                    if (res.data.type === "payment required") {
                        setPaymetLink(res.data.paymentUrl)
                        handleSecondShow()
                        setAwaitingLogin(false)
                    } else {
                        navigate('/')
                    }
                } else if (res.data.success === false) {
                    if (res.data.type === 'Inactive') {
                        handleSecondShow()
                        setAwaitingLogin(false)
                    } else {
                        setMessage(res.data.type)
                        setAwaitingLogin(false)
                    }
                }
            } else {

                setMessage("Please make sure all fields are filled")
                setAwaitingLogin(false)
            }
        } catch {
            navigate('/login')
        }
    }

    function handleSetEmail(value) {
        value = value.toLowerCase()
        setEmail(value)
    }

    function handleSecondCloseHandleShow() {
        handleSecondClose()
        handleShow()
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton
                    className='popup-model'>
                    <Modal.Title>
                        <b>
                            Payment
                        </b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className='popup-model'>
                    <div
                        className="total">
                        {/* Total: {total} */}
                        Total: $8 per year
                    </div>
                    <a
                        href={paymentLink}>
                        <Button
                            className="col-12 menuBtn">
                            Pay with <br /><img
                                alt="Stripe logo"
                                className='stripe-payment-logo'
                                src='stripe.png' />
                        </Button>
                    </a>
                </Modal.Body>
            </Modal>
            <Modal
                show={secondShow}
                onHide={handleSecondClose}>
                <Modal.Header
                    className='popup-model'
                    closeButton>
                    <Modal.Title>
                        <b>
                            Account Activation
                        </b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className='popup-model'>
                    <Row>
                        <div
                            className="col-12">
                            <Card
                                className="col-12 createAccountCard paymentButtonHighlight ">
                                <div className="paymentButton">
                                    <Card.Body>
                                        <Row>
                                            <div className="col-10 col-md-9 col-xl-10">
                                                <div className="paymentPrice">
                                                    <b>$8 a Year</b>
                                                </div>
                                                Reoccouring cost
                                            </div>
                                            <div className="col-2 col-md-3 col-xl-2">
                                                <img className="col-12"
                                                    src="reactive_pass_lettermark_alt.svg"
                                                    alt='rpass' />
                                            </div>
                                        </Row>
                                    </Card.Body>
                                </div>
                            </Card>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer
                    className='popup-model'>
                    <Button
                        className='col-12 menuBtn'
                        onClick={handleSecondCloseHandleShow}>
                        Activate
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container>
                <br />
                <Row>
                    <div className='col-md-2 col-lg-4' />
                    <Card className='col-12 col-md-8 col-lg-4 container-card'>
                        <Card.Body>
                            <Row>
                                <div className='col-3' />
                                <img className='loginPhoto col-6'
                                    src='reactive_pass_lettermark.svg'
                                    alt='rpass' />
                                <div className='col-3' />
                                <br />
                                <Form.Group>
                                    <Form.Label
                                        className='login-form-label'>
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        value={email}
                                        onChange={(e) => handleSetEmail(e.target.value)}
                                        className='input-background login-form-background'
                                    />
                                </Form.Group>
                                <br />
                                <Form.Group>
                                    <Form.Label
                                        className='login-form-label'
                                    >Password
                                    </Form.Label>
                                    <Form.Control
                                        type='password'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className='input-background login-form-background'
                                    />
                                </Form.Group>
                                <br />
                                <Form.Group>
                                    <Form.Label
                                        className='login-form-label'>2FA key (Optional)</Form.Label>
                                    <Form.Control
                                        type='token'
                                        value={token}
                                        onChange={(e) => setToken(e.target.value)}
                                        className='input-background login-form-background'
                                    />
                                </Form.Group>
                            </Row>
                            <br />
                            <Row>
                                {awaitingLogin ? (
                                    <>
                                        <Link>
                                            <div className='col-md-2' />
                                            <Button
                                                className='col-12 menuBtn'>
                                                <ClipLoader
                                                    color="#fff"
                                                    className="loadingInMenuBtn" />
                                            </Button>
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <Link>
                                            <div className='col-md-2' />
                                            <Button
                                                onClick={handleSubmit}
                                                className='col-12 menuBtn'
                                            >Login</Button>
                                        </Link>
                                    </>
                                )}
                            </Row>
                            <Row>
                                <div className='col-md-2' />
                                <Link
                                    to={'/create-account'}
                                    className='col-12'>
                                    <Button
                                        className='col-12 create-account-button'
                                    >Create an Account</Button>
                                </Link>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
                <center>
                    <br />
                    <Row>
                        <div className='col-12 col-md-2 col-lg-4' />
                        {message ? (
                            <>
                                <div className='message col-12 col-md-8 col-lg-4'>
                                    {message}
                                </div>
                                <br />
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </Row>
                </center>
            </Container>
        </>
    )
}
export default Loginpage