import { useContext, useEffect, useState } from "react"
import { Button, Container, Form, Row } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { UserContext } from "../contexts/UserContext"

function AdminEditAccount() {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [accountType, setAccountType] = useState('given')
    const [userType, setUserType] = useState('standard')
    const [active, setActive] = useState("true")

    const { getUser, editUser } = useContext(UserContext)
    let params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        async function gettingUser() {
            let user = await getUser(params.id)
            setUserType(user.userType)
            setActive(user.active)
            setName(user.name)
            setEmail(user.email)
            setAccountType(user.accountType)
        }
        gettingUser()
    }, [getUser, params.id])

    async function handleSubmit() {
        let editingUser = {
            active: active,
            userType: userType,
            userId: params.id
        }
        await editUser(editingUser)
        navigate('/')
    }

    return (
        <>
            <div
                className="searchNav">
                <Container>
                    <Row className="align-items-center">
                        <div className="col-4 col-md-3 col-lg-2 wordmark-area">
                            <img 
                            alt="Reactive Pass wordmark"
                            src="/reactive_pass_wordmark_alt.svg" 
                            className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-6 col-lg-7"></div>
                        <Link to="/" className="col-4 col-md-3">
                            <Button className="topMenuBtn w-100">
                                Home
                            </Button>
                        </Link>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <br />
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>Name</b></h3></Form.Label>
                        <br />
                        <div className="text-color">
                            {name}
                            <br />
                            Name can only be reset by the user
                        </div>
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>Email</b></h3></Form.Label>
                        <div className="text-color">
                            {email}
                            <br />
                            Email can only be reset by the user - upcoming feature
                        </div>
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>Password</b></h3></Form.Label>
                        <div className="text-color">
                        Password can only be reset by the user
                        </div>
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>AccountType</b></h3></Form.Label>
                        <div className="text-color">
                        {accountType}
                        <br />
                        This attribute should only be set by stripe
                        </div>
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>UserType</b></h3></Form.Label>
                        <Form.Select value={userType} onChange={(e) => setUserType(e.target.value)}>
                            <option value="standard">standard</option>
                            <option value="admin">admin</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="col-6">
                        <Form.Label><h3><b>Active</b></h3></Form.Label>
                        <Form.Select value={active} onChange={(e) => setActive(e.target.value)}>
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                        </Form.Select>
                    </Form.Group>
                    <br /><br /><br /><br /><br />
                </Row>
                <Row>
                    <Button onClick={handleSubmit}>
                        Submit
                    </Button>
                </Row>
            </Container>
        </>
    )
}
export default AdminEditAccount